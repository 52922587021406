import { Component, OnInit } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
  selector: "app-allume",
  templateUrl: "./allume.component.html",
  styleUrls: ["./allume.component.scss"],
})
export class AllumeComponent implements OnInit {
  isVisible = {
    desktop: false,
    tablet: false,
    mobile: false,
  };

  constructor(private readonly _device: DeviceDetectorService) {
    this.isVisible.desktop = this._device.isDesktop();
    this.isVisible.tablet = this._device.isTablet();
    this.isVisible.mobile = this._device.isMobile();
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }
}
