import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DevelopmentComponent } from "./pages/development-screens/development/development.component";
import { DriptoComponent } from "./pages/our-work-pages/dripto-screens/dripto/dripto.component";
import { HomeComponent } from "./pages/home-screens/home/home.component";
import { MeetTheProsComponent } from "./pages/meet-the-pros-screens/meet-the-pros/meet-the-pros.component";
import { OurClientsComponent } from "./pages/our-clients-screens/our-clients/our-clients.component";
import { A320Component } from "./pages/our-work-pages/a320-screens/a320/a320.component";
import { AlexandriaComponent } from "./pages/our-work-pages/alexandria-screens/alexandria/alexandria.component";
import { AllumeComponent } from "./pages/our-work-pages/allume-screens/allume/allume.component";
import { CrowdedComponent } from "./pages/our-work-pages/crowded-screens/crowded/crowded.component";
import { DuzyComponent } from "./pages/our-work-pages/duzy-screens/duzy/duzy.component";
import { GiftyaComponent } from "./pages/our-work-pages/giftya-screens/giftya/giftya.component";
import { NudjComponent } from "./pages/our-work-pages/nudj-screens/nudj/nudj.component";
import { OdysseyComponent } from "./pages/our-work-pages/odyssey-screens/odyssey/odyssey.component";
import { RoComponent } from "./pages/our-work-pages/ro-screens/ro/ro.component";
import { SaratogaComponent } from "./pages/our-work-pages/saratoga-screens/saratoga/saratoga.component";
import { StanleyComponent } from "./pages/our-work-pages/stanley-screens/stanley/stanley.component";
import { WingComponent } from "./pages/our-work-pages/wing-screens/wing/wing.component";
import { OurWorkComponent } from "./pages/our-work-screens/our-work/our-work.component";
import { ScheduleACallComponent } from "./pages/schedule-a-call-screens/schedule-a-call/schedule-a-call.component";
import { RhythmComponent } from "./pages/our-work-pages/rhythm-screens/rhythm/rhythm.component";
import { PrivacyPolicyComponent } from "./pages/privacy-policy-screens/privacy-policy/privacy-policy.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path: "home",
    component: HomeComponent,
  },
  {
    path: "our-clients",
    component: OurClientsComponent,
  },
  {
    path: "our-work",
    children: [
      {
        path: "",
        component: OurWorkComponent,
      },
      {
        path: "allume",
        component: AllumeComponent,
      },
      {
        path: "duzy",
        component: DuzyComponent,
      },
      {
        path: "nudj",
        component: NudjComponent
      },
      {
        path: "alexandria",
        component: AlexandriaComponent
      },
      {
        path: "giftya",
        component: GiftyaComponent
      },
      {
        path: "stanley",
        component: StanleyComponent
      },
      {
        path: "saratoga",
        component: SaratogaComponent
      },
      {
        path: "ro",
        component: RoComponent
      },
      {
        path: "odyssey",
        component: OdysseyComponent
      },
      {
        path: "a320",
        component: A320Component
      },
      {
        path: "wing",
        component: WingComponent
      },
      {
        path: "crowded",
        component: CrowdedComponent
      },
      {
        path: "dripto",
        component: DriptoComponent
      },
      {
        path: "rhythm",
        component: RhythmComponent
      }
    ],
  },
  {
    path: "meet-the-pros",
    component: MeetTheProsComponent,
  },
  {
    path: "development",
    component: DevelopmentComponent,
  },
  {
    path: "schedule-a-call",
    component: ScheduleACallComponent,
  },
  {
    path: "privacy-policy",
    component: PrivacyPolicyComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
