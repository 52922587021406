import { Component, HostListener, OnInit } from "@angular/core";
import { UtilsService } from "src/app/helpers/utils.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-a320-tablet",
  templateUrl: "./a320-tablet.component.html",
  styleUrls: ["./a320-tablet.component.scss"],
})
export class A320TabletComponent implements OnInit {

  imagesForPage = [
    { name: "image_1.png" },
    { name: "image_2.png" },
    { name: "image_3.png" },
    { name: "image_4.png" },
    { name: "image_5.png" },
    { name: "image_6.png" },
    { name: "image_7.png" },
    { name: "a320_logo_large.png" },
  ];

  images = [];

  constructor(
    private readonly _router: Router,
    private readonly _utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.images = this._utilsService.createRandomCaseStudies(7);
  }

  backClickEvent(): void {
    this._router.navigate(['../our-work']);
  }
}
