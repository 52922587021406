import { Component, OnInit } from "@angular/core";
import { UtilsService } from "src/app/helpers/utils.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-saratoga-mobile",
  templateUrl: "./saratoga-mobile.component.html",
  styleUrls: ["./saratoga-mobile.component.scss"],
})
export class SaratogaMobileComponent implements OnInit {

  imagesForPage = [
    { name: "case_image_1.png" },
    { name: "case_image_2.png" },
    { name: "case_image_3.png" },
    { name: "case_image_4.png" },
    { name: "case_image_5.png" },
    { name: "case_image_6.png" },
    { name: "case_image_7.png" },
    { name: "case_logo.png" },
  ];

  images = [];

  constructor(
    private readonly _router: Router,
    private readonly _utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.images = this._utilsService.createRandomCaseStudies(8);
  }

  backClickEvent(): void {
    this._router.navigate(['../our-work']);
  }

  viewDesignSystemClickEvent(): void {
    window.open("https://www.figma.com/file/GrLs4NdXEvXFyga8s2w7Iv/(Shared)-Saratoga-Design-System?node-id=0%3A1", "_blank");
  }
}
