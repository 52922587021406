import { Component, HostListener, OnInit } from "@angular/core";

@Component({
  selector: 'app-development-tablet',
  templateUrl: './development-tablet.component.html',
  styleUrls: ['./development-tablet.component.scss']
})
export class DevelopmentTabletComponent implements OnInit {

  isMobile: boolean = false;
  screenSize: number = 0;

  constructor() {}

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.onResize();
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.screenSize = window.innerWidth;

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        navigator.userAgent
      )
    ) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }
}


