import { ViewportScroller } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";

@Component({
  selector: 'app-home-tablet',
  templateUrl: './home-tablet.component.html',
  styleUrls: ['./home-tablet.component.scss']
})
export class HomeTabletComponent implements OnInit {

  playerConfig = {
    // controls: 0,
    mute: 1,
    autoplay: 1,
    loop: 1,
    playlist: "AtZ1AM7Wprw",
  };

  videoWidth: number | undefined;
  videoHeight: number | undefined;

  isMobile: boolean = false;
  screenSize: number = 0;

  @ViewChild("homePage") homePage: ElementRef<HTMLDivElement>;

  constructor(
    private readonly _scroll: ViewportScroller,
    private readonly _changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);

    const tag = document.createElement("script");

    tag.src = "https://www.youtube.com/iframe_api";
    document.body.appendChild(tag);

    this.onResize();
  }

  ngAfterViewInit() {
    this.onResize();

    const videoEl = document.getElementById("video") as HTMLVideoElement;
    if (videoEl) videoEl.muted = true;
  }

  scrollToTop() {
    this._scroll.scrollToPosition([0, 0]);
  }

  pageYoffset = 0;
  @HostListener("window:scroll", ["$event"]) onScroll(event) {
    this.pageYoffset = window.pageYOffset;

    const videoElement = document.getElementById("video");
    const videoIsVisible = this.isScrolledIntoView(videoElement);

    const stickyElement = document.getElementById("sticky");
    if (videoIsVisible) {
      stickyElement.style.position = "relative";
    } else {
      stickyElement.style.position = "fixed";
    }
  }

  isScrolledIntoView(el) {
    var rect = el.getBoundingClientRect();
    // var elemBottom = rect.bottom;
    var elemTop = rect.top;

    // Only completely visible elements return true:
    // var isVisible = (elemBottom - 150 <= window.innerHeight);
    // Partially visible elements return true:
    var isVisible = elemTop < window.innerHeight;
    return isVisible;
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.screenSize = window.innerWidth;

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        navigator.userAgent
      )
    ) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

    // Automatically expand the video to fit the page up to 1080px x 720px
    if (this.homePage) {
      this.videoWidth = Math.min(this.homePage.nativeElement.clientWidth, 1080);
      this.videoWidth = this.videoWidth * 0.8;
      this.videoHeight = this.videoWidth * 0.6;
    } else {
      this.videoWidth = 1080;
      this.videoHeight = 600;
    }

    this._changeDetectorRef.detectChanges();
  }
}


