import { Component, HostListener, OnInit } from "@angular/core";

@Component({
  selector: "app-meet-the-pros-mobile",
  templateUrl: "./meet-the-pros-mobile.component.html",
  styleUrls: ["./meet-the-pros-mobile.component.scss"],
})
export class MeetTheProsMobileComponent implements OnInit {
  items = [
    {
      info: "Cd",
      image: "pros_1.svg",
      name: "William Burton",
      position: "Creative Director",
    },
    {
      info: "Ux",
      image: "pros_2.svg",
      name: "Lydia Brooker",
      position: "Lead UX Designer",
    },
    {
      info: "Ux",
      image: "pros_3.svg",
      name: "Jill Danielle Liao",
      position: "Senior UX Designer",
    },
    {
      info: "Ux",
      image: "pros_4.svg",
      name: "Jin Lee",
      position: "UX Designer",
    },
    {
      info: "Ux",
      image: "pros_5.svg",
      name: "Cejay Flores",
      position: "UX Designer",
    },
    // {
    //   info: "Ux",
    //   image: "pros_6.svg",
    //   name: "Nicole Reed",
    //   position: "Junior UX Designer",
    // },
    {
      info: "Ux",
      image: "pros_7.svg",
      name: "Zion Burton",
      position: "Junior UX Designer",
    },
    {
      info: "Md",
      image: "pros_9.svg",
      name: "Stephanie Nestorak",
      position: "Motion Designer",
    },
    {
      info: "Dm",
      image: "pros_8.svg",
      name: "Camila Brango",
      position: "Digital Marketing",
    },
    {
      info: "Bo",
      image: "pros_10.svg",
      name: "Belinda Ortiz",
      position: "Business Operations",
    },
  ];

  isMobile: boolean = false;
  screenSize: number = 0;

  constructor() {}

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.onResize();
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.screenSize = window.innerWidth;

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        navigator.userAgent
      )
    ) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }
}
