import { Component, HostListener, OnInit } from "@angular/core";

@Component({
  selector: 'app-our-clients-desktop',
  templateUrl: './our-clients-desktop.component.html',
  styleUrls: ['./our-clients-desktop.component.scss']
})
export class OurClientsDesktopComponent implements OnInit {
  desktopImages = [
    { name: "client_1.svg" },
    { name: "client_2.svg" },
    { name: "client_3.svg" },
    { name: "client_4.svg" },
    { name: "client_5.svg" },
    { name: "client_6.svg" },
    { name: "client_7.svg" },
    { name: "client_8.svg" },
    { name: "client_9.svg" },
    { name: "client_10.svg" },
    { name: "client_11.svg" },
    { name: "client_12.svg" },
    { name: "client_13.svg" },
    { name: "client_14.svg" },
    { name: "client_15.svg" },
    { name: "client_16.svg" },
    { name: "client_17.svg" },
    { name: "client_18.svg" },
  ];

  mobileImages = [
    { name: "client_1_small.svg" },
    { name: "client_2_small.svg" },
    { name: "client_3_small.svg" },
    { name: "client_4_small.svg" },
    { name: "client_5_small.svg" },
    { name: "client_6_small.svg" },
    { name: "client_7_small.svg" },
    { name: "client_8_small.svg" },
    { name: "client_9_small.svg" },
    { name: "client_10_small.svg" },
    { name: "client_11_small.svg" },
    { name: "client_12_small.svg" },
    { name: "client_13_small.svg" },
    { name: "client_14_small.svg" },
    { name: "client_15_small.svg" },
    { name: "client_16_small.svg" },
    { name: "client_17_small.svg" },
    { name: "client_18_small.svg" },
  ];

  largeImages = [
    { name: "client_1_large.svg" },
    { name: "client_2_large.svg" },
    { name: "client_3_large.svg" },
    { name: "client_4_large.svg" },
    { name: "client_5_large.svg" },
    { name: "client_6_large.svg" },
    { name: "client_7_large.svg" },
    { name: "client_8_large.svg" },
    { name: "client_9_large.svg" },
    { name: "client_10_large.svg" },
    { name: "client_11_large.svg" },
    { name: "client_12_large.svg" },
    { name: "client_13_large.svg" },
    { name: "client_14_large.svg" },
    { name: "client_15_large.svg" },
    { name: "client_16_large.svg" },
    { name: "client_17_large.svg" },
    { name: "client_18_large.svg" },
  ];

  images = this.desktopImages;

  isMobile: boolean = false;
  screenSize: number = 0;

  constructor() {}

  ngOnInit(): void {
    this.images = this.desktopImages;
    window.scrollTo(0, 0);

    this.onResize();
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.screenSize = window.innerWidth;

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        navigator.userAgent
      )
    ) {
      if (window.innerWidth < 843) {
        this.images = this.largeImages;
      } else if (window.innerWidth > 960 && window.innerWidth < 1440) {
        this.images = this.mobileImages;
      } else {
        this.images = this.desktopImages;
      }
      this.isMobile = true;
    } else if (window.innerWidth > 960 && window.innerWidth < 1440) {
      this.images = this.mobileImages;
      this.isMobile = false;
    } else {
      this.images = this.desktopImages;
      this.isMobile = false;
    }
  }
}

