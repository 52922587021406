import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "./modules/material.module";
import { ComponentsModule } from "./components/components.module";

@NgModule({
  imports: [CommonModule, ComponentsModule, MaterialModule],
  exports: [ComponentsModule, MaterialModule],
})
export class SharedModule {}
