import { Component, OnInit } from "@angular/core";
import { UtilsService } from "src/app/helpers/utils.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-giftya-mobile",
  templateUrl: "./giftya-mobile.component.html",
  styleUrls: ["./giftya-mobile.component.scss"],
})
export class GiftyaMobileComponent implements OnInit {
  
  imagesForPage = [
    { name: "case_image_1.png" },
    { name: "case_image_2.png" },
    { name: "case_image_3.png" },
    { name: "case_image_4.png" },
    { name: "case_image_5.png" },
    { name: "case_image_6.png" },
    { name: "case_image_7.png" },
    { name: "case_logo.png" },
  ];

  images = [];

  constructor(
    private readonly _router: Router,
    private readonly _utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.images = this._utilsService.createRandomCaseStudies(13);
  }

  backClickEvent(): void {
    this._router.navigate(['../our-work']);
  }
}
