import { Component, HostListener, OnInit } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
  selector: "app-side-nav",
  templateUrl: "./side-nav.component.html",
  styleUrls: ["./side-nav.component.scss"],
})
export class SideNavComponent implements OnInit {
  isVisible = {
    desktop: false,
    tablet: false,
    mobile: false
  };

  screenWidth: number = 0;

  constructor(private readonly _device: DeviceDetectorService) {
    this.isVisible.desktop = this._device.isDesktop();
    this.isVisible.tablet = this._device.isTablet();
    this.isVisible.mobile = this._device.isMobile();

    this.onResize();
  }

  ngOnInit(): void { }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.screenWidth = window.innerWidth;
  }
}
