import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  private listCaseStudies: Array<{ name: string; path: string }> = [
    { name: "alexandria.svg", path: "alexandria" },
    { name: "stanley.svg", path: "stanley" },
    { name: "rhythm.svg", path: "rhythm" },
    { name: "dripto.svg", path: "dripto" },
    { name: "odyssey.svg", path: "odyssey" },
    { name: "wing.svg", path: "wing" },
    { name: "a320.svg", path: "a320" },
    { name: "saratoga.svg", path: "saratoga" },
    { name: "crowded.svg", path: "crowded" },
    { name: "nudj.svg", path: "nudj" },
    { name: "duzy.svg", path: "duzy" },
    { name: "allume.svg", path: "allume" },
    { name: "giftya.svg", path: "giftya" },
    { name: "ro.svg", path: "ro" },
  ];

  /**
   * Create a list of three random case studies except
   * case studies that users opened
   * @param skip number
   * @returns Array<{ name: string }>
   */
  createRandomCaseStudies(skip: number): Array<{ name: string }> {
    // Create copy of list case studies without reference
    const copyOfListCaseStudies = JSON.parse(
      JSON.stringify(this.listCaseStudies)
    );

    let filteredListCaseStudies = new Array<{ name: string }>();
    const random = this.createRandomNumbers(13);

    //* Removed case studies that users open
    if (skip > 0) copyOfListCaseStudies.splice(skip - 1, 1);

    random.forEach((element) => {
      filteredListCaseStudies.push(copyOfListCaseStudies[element]);
    });

    return filteredListCaseStudies;
  }

  /**
   * Create list of three random numbers
   * @param range number
   * @returns list Array<number>
   */
  private createRandomNumbers(range: number): Array<number> {
    const list = new Array<number>();

    while (list.length < 3) {
      const number = Math.floor(Math.random() * range);

      if (!list.includes(number)) list.push(number);
    }

    return list;
  }
}
