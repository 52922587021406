// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "visbyCF";
  src: url('VisbyCF-Light.otf') format("truetype");
}
@font-face {
  font-family: "openSans";
  src: url('OpenSans.ttf') format("opentype");
}
@font-face {
  font-family: "Shadows Into Light";
  src: url('ShadowsIntoLight-Regular.ttf') format("opentype");
}
:host {
  height: 100%;
  background: #161c29;
}
:host .container {
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-left: 238px;
}
:host .container .calendly-container {
  width: 100%;
  height: calc(100vh - 122px);
  margin-left: 10px;
  margin-right: 10px;
}
@media (max-width: 900px) {
  :host .container {
    padding-left: 10px;
    padding-right: 10px;
  }
  :host .container .calendly-container {
    margin-top: 100px !important;
    height: 100vh;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/styles/fonts.scss","webpack://./src/app/pages/schedule-a-call-screens/schedule-a-call-tablet/schedule-a-call-tablet.component.scss","webpack://./src/app/styles/color-definition.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,gDAAA;ACCJ;ADEA;EACI,uBAAA;EACA,2CAAA;ACAJ;ADGA;EACI,iCAAA;EACA,2DAAA;ACDJ;AARA;EACE,YAAA;EACA,mBCLmB;ADerB;AARE;EACE,YAAA;EACA,uBAAA;EACA,mBAAA;EAEA,kBAAA;AASJ;AAPI;EACE,WAAA;EACA,2BAAA;EACA,iBAAA;EACA,kBAAA;AASN;AALE;EACE;IACE,kBAAA;IACA,mBAAA;EAOJ;EALI;IACE,4BAAA;IACA,aAAA;EAON;AACF","sourcesContent":["@font-face {\n    font-family: \"visbyCF\";\n    src: url(\"../../assets/fonts/VisbyCF-Light.otf\") format(\"truetype\");\n}\n\n@font-face {\n    font-family: \"openSans\";\n    src: url(\"../../assets/fonts/OpenSans.ttf\") format(\"opentype\");\n}\n\n@font-face {\n    font-family: \"Shadows Into Light\";\n    src: url(\"../../assets/fonts/ShadowsIntoLight-Regular.ttf\") format(\"opentype\");\n}","@import \"../../../styles/color-definition.scss\";\n@import \"../../../styles/fonts.scss\";\n\n:host {\n  height: 100%;\n  background: $black-background-1;\n\n  .container {\n    height: 100%;\n    justify-content: center;\n    align-items: center;\n\n    margin-left: 238px;\n\n    .calendly-container {\n      width: 100%;\n      height: calc(100vh - 122px);\n      margin-left: 10px;\n      margin-right: 10px;\n    }\n  }\n\n  @media (max-width: 900px) {\n    .container {\n      padding-left: 10px;\n      padding-right: 10px;\n\n      .calendly-container {\n        margin-top: 100px !important;\n        height: 100vh;\n      }\n    }\n  }\n}\n","$black-background-1: #161c29;\n$black-background-2: #1c2230;\n$white-soft: #fafcfd;\n$blue: #008cff;\n$white: #ffffff;\n$text: #8d9dad;\n$gray-1: #60646e;\n$gray-2: #b6bbc6;\n$blue-ux-pros: rgba(0, 140, 255, 0.2);\n$box-shadow-color: rgba(48, 98, 247, 0.57); "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
