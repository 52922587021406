import { Component, OnInit } from "@angular/core";

export {};
declare global {
  interface Window {
    Calendly: any;
  }
}

@Component({
  selector: "app-schedule-a-call-tablet",
  templateUrl: "./schedule-a-call-tablet.component.html",
  styleUrls: ["./schedule-a-call-tablet.component.scss"],
})
export class ScheduleACallTabletComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    window.scrollTo(0, 0);

    window.Calendly.initInlineWidget({
          url: "https://calendly.com/uxpros/30min?hide_landing_page_details=1&hide_gdpr_banner=1&background_color=1c2230&text_color=fafcfd&primary_color=008cff",
      //url: "https://calendly.com/d/dn8-xvy-xj2/discovery-call-uxpros-com/?hide_landing_page_details=1&hide_gdpr_banner=1&background_color=1c2230&text_color=fafcfd&primary_color=008cff&back=1",
      parentElement: document.querySelector(".calendly-inline-widget"),
    });
  }
}
