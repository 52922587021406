import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-side-nav-desktop",
  templateUrl: "./side-nav-desktop.component.html",
  styleUrls: ["./side-nav-desktop.component.scss"],
})
export class SideNavDesktopComponent implements OnInit {
  selectedNavItem = {
    home: true,
    "our-clients": false,
    "our-work": false,
    "meet-the-pros": false,
    development: false,
    "schedule-a-call": false,
  };

  constructor(private readonly _router: Router) {
    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationStart) => {
        this.navItemChangeEvent(event.url);
      });
  }

  ngOnInit(): void {}

  navItemChangeEvent(url: string): void {
    this.selectedNavItem[this.findTerm(url)] = true;
  }

  findTerm(url: string): string {
    const routes = [
      "home",
      "our-clients",
      "our-work",
      "meet-the-pros",
      "development",
      "schedule-a-call",
    ];
    let term = "";

    //* When you first open the site (url will be empty)
    if (url === "/") return "home";

    routes.forEach((item) => {
      // Reset menu items
      this.selectedNavItem[item] = false;
      if (url.includes(item)) term = item;
    });

    return term;
  }

  gmailEventClick(): void {
    window.location.href = "mailto:business@uxpros.com";
  }

  linkedInEventClick(): void {
    window.open("https://www.linkedin.com/company/uxpros");
  }

  instagramEventClick(): void {
    window.open("https://www.instagram.com/ux_pros/");
  }
}
