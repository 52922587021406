import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Routes, RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { HomeComponent } from './pages/home-screens/home/home.component';
import { OurClientsComponent } from './pages/our-clients-screens/our-clients/our-clients.component';
import { OurWorkComponent } from './pages/our-work-screens/our-work/our-work.component';
import { MeetTheProsComponent } from './pages/meet-the-pros-screens/meet-the-pros/meet-the-pros.component';
import { ScheduleACallComponent } from './pages/schedule-a-call-screens/schedule-a-call/schedule-a-call.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { DevelopmentComponent } from './pages/development-screens/development/development.component';
import { CustomBreakPointsProvider } from './helpers/custom-breakpoints';
import { AllumeComponent } from './pages/our-work-pages/allume-screens/allume/allume.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AllumeTabletComponent } from './pages/our-work-pages/allume-screens/allume-tablet/allume-tablet.component';
import { AllumeMobileComponent } from './pages/our-work-pages/allume-screens/allume-mobile/allume-mobile.component';
import { AllumeDesktopComponent } from './pages/our-work-pages/allume-screens/allume-desktop/allume-desktop.component';
import { DuzyComponent } from './pages/our-work-pages/duzy-screens/duzy/duzy.component';
import { DuzyDesktopComponent } from './pages/our-work-pages/duzy-screens/duzy-desktop/duzy-desktop.component';
import { DuzyTabletComponent } from './pages/our-work-pages/duzy-screens/duzy-tablet/duzy-tablet.component';
import { DuzyMobileComponent } from './pages/our-work-pages/duzy-screens/duzy-mobile/duzy-mobile.component';
import { NudjComponent } from './pages/our-work-pages/nudj-screens/nudj/nudj.component';
import { NudjDesktopComponent } from './pages/our-work-pages/nudj-screens/nudj-desktop/nudj-desktop.component';
import { NudjMobileComponent } from './pages/our-work-pages/nudj-screens/nudj-mobile/nudj-mobile.component';
import { NudjTabletComponent } from './pages/our-work-pages/nudj-screens/nudj-tablet/nudj-tablet.component';
import { AlexandriaComponent } from './pages/our-work-pages/alexandria-screens/alexandria/alexandria.component';
import { AlexandriaDesktopComponent } from './pages/our-work-pages/alexandria-screens/alexandria-desktop/alexandria-desktop.component';
import { AlexandriaTabletComponent } from './pages/our-work-pages/alexandria-screens/alexandria-tablet/alexandria-tablet.component';
import { AlexandriaMobileComponent } from './pages/our-work-pages/alexandria-screens/alexandria-mobile/alexandria-mobile.component';
import { GiftyaComponent } from './pages/our-work-pages/giftya-screens/giftya/giftya.component';
import { GiftyaDesktopComponent } from './pages/our-work-pages/giftya-screens/giftya-desktop/giftya-desktop.component';
import { GiftyaTabletComponent } from './pages/our-work-pages/giftya-screens/giftya-tablet/giftya-tablet.component';
import { GiftyaMobileComponent } from './pages/our-work-pages/giftya-screens/giftya-mobile/giftya-mobile.component';
import { StanleyComponent } from './pages/our-work-pages/stanley-screens/stanley/stanley.component';
import { StanleyDesktopComponent } from './pages/our-work-pages/stanley-screens/stanley-desktop/stanley-desktop.component';
import { StanleyTabletComponent } from './pages/our-work-pages/stanley-screens/stanley-tablet/stanley-tablet.component';
import { StanleyMobileComponent } from './pages/our-work-pages/stanley-screens/stanley-mobile/stanley-mobile.component';
import { SaratogaComponent } from './pages/our-work-pages/saratoga-screens/saratoga/saratoga.component';
import { SaratogaDesktopComponent } from './pages/our-work-pages/saratoga-screens/saratoga-desktop/saratoga-desktop.component';
import { SaratogaTabletComponent } from './pages/our-work-pages/saratoga-screens/saratoga-tablet/saratoga-tablet.component';
import { SaratogaMobileComponent } from './pages/our-work-pages/saratoga-screens/saratoga-mobile/saratoga-mobile.component';
import { RoComponent } from './pages/our-work-pages/ro-screens/ro/ro.component';
import { RoDesktopComponent } from './pages/our-work-pages/ro-screens/ro-desktop/ro-desktop.component';
import { RoTabletComponent } from './pages/our-work-pages/ro-screens/ro-tablet/ro-tablet.component';
import { RoMobileComponent } from './pages/our-work-pages/ro-screens/ro-mobile/ro-mobile.component';
import { OdysseyComponent } from './pages/our-work-pages/odyssey-screens/odyssey/odyssey.component';
import { OdysseyDesktopComponent } from './pages/our-work-pages/odyssey-screens/odyssey-desktop/odyssey-desktop.component';
import { OdysseyTabletComponent } from './pages/our-work-pages/odyssey-screens/odyssey-tablet/odyssey-tablet.component';
import { OdysseyMobileComponent } from './pages/our-work-pages/odyssey-screens/odyssey-mobile/odyssey-mobile.component';
import { A320Component } from './pages/our-work-pages/a320-screens/a320/a320.component';
import { A320DesktopComponent } from './pages/our-work-pages/a320-screens/a320-desktop/a320-desktop.component';
import { A320TabletComponent } from './pages/our-work-pages/a320-screens/a320-tablet/a320-tablet.component';
import { A320MobileComponent } from './pages/our-work-pages/a320-screens/a320-mobile/a320-mobile.component';
import { WingComponent } from './pages/our-work-pages/wing-screens/wing/wing.component';
import { WingDesktopComponent } from './pages/our-work-pages/wing-screens/wing-desktop/wing-desktop.component';
import { WingTabletComponent } from './pages/our-work-pages/wing-screens/wing-tablet/wing-tablet.component';
import { WingMobileComponent } from './pages/our-work-pages/wing-screens/wing-mobile/wing-mobile.component';
import { CrowdedComponent } from './pages/our-work-pages/crowded-screens/crowded/crowded.component';
import { CrowdedDesktopComponent } from './pages/our-work-pages/crowded-screens/crowded-desktop/crowded-desktop.component';
import { CrowdedTabletComponent } from './pages/our-work-pages/crowded-screens/crowded-tablet/crowded-tablet.component';
import { CrowdedMobileComponent } from './pages/our-work-pages/crowded-screens/crowded-mobile/crowded-mobile.component';
import { DriptoComponent } from './pages/our-work-pages/dripto-screens/dripto/dripto.component';
import { DriptoDesktopComponent } from './pages/our-work-pages/dripto-screens/dripto-desktop/dripto-desktop.component';
import { DriptoTabletComponent } from './pages/our-work-pages/dripto-screens/dripto-tablet/dripto-tablet.component';
import { DriptoMobileComponent } from './pages/our-work-pages/dripto-screens/dripto-mobile/dripto-mobile.component';

import { RhythmComponent } from './pages/our-work-pages/rhythm-screens/rhythm/rhythm.component';
import { RhythmDesktopComponent } from './pages/our-work-pages/rhythm-screens/rhythm-desktop/rhythm-desktop.component';
import { RhythmTabletComponent } from './pages/our-work-pages/rhythm-screens/rhythm-tablet/rhythm-tablet.component';
import { RhythmMobileComponent } from './pages/our-work-pages/rhythm-screens/rhythm-mobile/rhythm-mobile.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { HomeTabletComponent } from './pages/home-screens/home-tablet/home-tablet.component';
import { HomeMobileComponent } from './pages/home-screens/home-mobile/home-mobile.component';
import { HomeDesktopComponent } from './pages/home-screens/home-desktop/home-desktop.component';
import { DevelopmentDesktopComponent } from './pages/development-screens/development-desktop/development-desktop.component';
import { DevelopmentTabletComponent } from './pages/development-screens/development-tablet/development-tablet.component';
import { DevelopmentMobileComponent } from './pages/development-screens/development-mobile/development-mobile.component';
import { ScheduleACallMobileComponent } from './pages/schedule-a-call-screens/schedule-a-call-mobile/schedule-a-call-mobile.component';
import { ScheduleACallDesktopComponent } from './pages/schedule-a-call-screens/schedule-a-call-desktop/schedule-a-call-desktop.component';
import { ScheduleACallTabletComponent } from './pages/schedule-a-call-screens/schedule-a-call-tablet/schedule-a-call-tablet.component';
import { MeetTheProsTabletComponent } from './pages/meet-the-pros-screens/meet-the-pros-tablet/meet-the-pros-tablet.component';
import { MeetTheProsDesktopComponent } from './pages/meet-the-pros-screens/meet-the-pros-desktop/meet-the-pros-desktop.component';
import { MeetTheProsMobileComponent } from './pages/meet-the-pros-screens/meet-the-pros-mobile/meet-the-pros-mobile.component';
import { OurClientsMobileComponent } from './pages/our-clients-screens/our-clients-mobile/our-clients-mobile.component';
import { OurClientsDesktopComponent } from './pages/our-clients-screens/our-clients-desktop/our-clients-desktop.component';
import { OurClientsTabletComponent } from './pages/our-clients-screens/our-clients-tablet/our-clients-tablet.component';
import { OurWorkTabletComponent } from './pages/our-work-screens/our-work-tablet/our-work-tablet.component';
import { OurWorkMobileComponent } from './pages/our-work-screens/our-work-mobile/our-work-mobile.component';
import { OurWorkDesktopComponent } from './pages/our-work-screens/our-work-desktop/our-work-desktop.component';

import { PrivacyPolicyComponent } from './pages/privacy-policy-screens/privacy-policy/privacy-policy.component';
import { PrivacyPolicyTabletComponent } from './pages/privacy-policy-screens/privacy-policy-tablet/privacy-policy-tablet.component';
import { PrivacyPolicyMobileComponent } from './pages/privacy-policy-screens/privacy-policy-mobile/privacy-policy-mobile.component';
import { PrivacyPolicyDesktopComponent } from './pages/privacy-policy-screens/privacy-policy-desktop/privacy-policy-desktop.component';

const routes: Routes = [

];

@NgModule({
  declarations: [
    AppComponent,

    HomeComponent,
    OurClientsComponent,
    OurWorkComponent,
    MeetTheProsComponent,
    ScheduleACallComponent,
    DevelopmentComponent,
    AllumeComponent,
    AllumeTabletComponent,
    AllumeMobileComponent,
    AllumeDesktopComponent,
    DuzyComponent,
    DuzyDesktopComponent,
    DuzyTabletComponent,
    DuzyMobileComponent,
    NudjComponent,
    NudjDesktopComponent,
    NudjMobileComponent,
    NudjTabletComponent,
    AlexandriaComponent,
    AlexandriaDesktopComponent,
    AlexandriaTabletComponent,
    AlexandriaMobileComponent,
    GiftyaComponent,
    GiftyaDesktopComponent,
    GiftyaTabletComponent,
    GiftyaMobileComponent,
    StanleyComponent,
    StanleyDesktopComponent,
    StanleyTabletComponent,
    StanleyMobileComponent,
    SaratogaComponent,
    SaratogaDesktopComponent,
    SaratogaTabletComponent,
    SaratogaMobileComponent,
    RoComponent,
    RoDesktopComponent,
    RoTabletComponent,
    RoMobileComponent,
    OdysseyComponent,
    OdysseyDesktopComponent,
    OdysseyTabletComponent,
    OdysseyMobileComponent,
    A320Component,
    A320DesktopComponent,
    A320TabletComponent,
    A320MobileComponent,
    WingComponent,
    WingDesktopComponent,
    WingTabletComponent,
    WingMobileComponent,
    CrowdedComponent,
    CrowdedDesktopComponent,
    CrowdedTabletComponent,
    CrowdedMobileComponent,
    DriptoComponent,
    DriptoDesktopComponent,
    DriptoTabletComponent,
    DriptoMobileComponent,
    RhythmComponent,
    RhythmDesktopComponent,
    RhythmTabletComponent,
    RhythmMobileComponent,
    HomeTabletComponent,
    HomeMobileComponent,
    HomeDesktopComponent,
    DevelopmentDesktopComponent,
    DevelopmentTabletComponent,
    DevelopmentMobileComponent,
    ScheduleACallMobileComponent,
    ScheduleACallDesktopComponent,
    ScheduleACallTabletComponent,
    MeetTheProsTabletComponent,
    MeetTheProsDesktopComponent,
    MeetTheProsMobileComponent,
    OurClientsMobileComponent,
    OurClientsDesktopComponent,
    OurClientsTabletComponent,
    OurWorkTabletComponent,
    OurWorkMobileComponent,
    OurWorkDesktopComponent,
    PrivacyPolicyComponent,
    PrivacyPolicyTabletComponent,
    PrivacyPolicyMobileComponent,
    PrivacyPolicyDesktopComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    RouterModule.forRoot(routes, { useHash: true }),  // .../#/crisis-center/
    SharedModule,
    NgxGoogleAnalyticsModule.forRoot('UA-139061143-1'),
    NgxGoogleAnalyticsRouterModule
  ],
  providers: [
    DeviceDetectorService,
    CustomBreakPointsProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
