import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SideNavComponent } from "./side-nav-screens/side-nav/side-nav.component";
import { MaterialModule } from "../modules/material.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LayoutModule } from "@angular/cdk/layout";
import { AppRoutingModule } from "src/app/app-routing.module";
import { FooterComponent } from './footer/footer.component';
import { SideNavMobileComponent } from './side-nav-screens/side-nav-mobile/side-nav-mobile.component';
import { SideNavDesktopComponent } from './side-nav-screens/side-nav-desktop/side-nav-desktop.component';
import { SideNavTabletComponent } from './side-nav-screens/side-nav-tablet/side-nav-tablet.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    
    AppRoutingModule
  ],
  declarations: [SideNavComponent, FooterComponent, SideNavMobileComponent, SideNavDesktopComponent, SideNavTabletComponent],
  exports: [SideNavComponent, FooterComponent, SideNavDesktopComponent, SideNavMobileComponent],
})
export class ComponentsModule {}
