import { Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: 'app-our-work-desktop',
  templateUrl: './our-work-desktop.component.html',
  styleUrls: ['./our-work-desktop.component.scss']
})
export class OurWorkDesktopComponent implements OnInit {
  images = [
    { name: "our-work_1.png", route: "alexandria" },
    { name: "our-work_2.png", route: "stanley" },
    { name: "our-work_3.png", route: "rhythm" },
    { name: "our-work_4.png", route: "dripto" },
    { name: "our-work_5.png", route: "odyssey" },
    { name: "our-work_6.png", route: "wing" },
    { name: "our-work_7.png", route: "a320" },
    { name: "our-work_8.png", route: "saratoga" },
    { name: "our-work_9.png", route: "crowded" },
    { name: "our-work_10.png", route: "nudj" },
    { name: "our-work_11.png", route: "duzy" },
    { name: "our-work_12.png", route: "allume" },
    { name: "our-work_13.png", route: "giftya" },
    { name: "our-work_14.png", route: "ro" },
  ];

  isMobile: boolean = false;
  screenSize: number = 0;

  constructor(
    private readonly _router: Router
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.onResize();
  }

  openOurWorkDetailsPage(route: string): void {
    this._router.navigate([`/our-work/${route}`]);
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.screenSize = window.innerWidth;

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        navigator.userAgent
      )
    ) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }
}

