import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  gmailEventClick(): void {
    window.location.href = "mailto:business@uxpros.com";
  }

  linkedInEventClick(): void {
    window.open('https://www.linkedin.com/company/uxpros');
  }

  instagramEventClick(): void {
    window.open('https://www.instagram.com/ux_pros/');
  }
}
