import { Component, HostListener, OnInit } from "@angular/core";
import {
  animate,
  sequence,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { UtilsService } from "src/app/helpers/utils.service";
import { Router } from "@angular/router";

export const rowsAnimation = trigger("rowsAnimation", [
  transition("void => *", [
    style({
      height: "*",
      opacity: "0",
      transform: "translateY(650px)",
      "box-shadow": "none",
    }),
    sequence([
      animate(
        ".45s ease",
        style({
          height: "*",
          opacity: ".3",
          transform: "translateY(0)",
          "box-shadow": "none",
        })
      ),
      animate(
        ".45s ease",
        style({
          height: "*",
          opacity: 1,
          transform: "translateX(0)",
        })
      ),
    ]),
  ]),
]);

@Component({
  selector: 'app-alexandria-desktop',
  templateUrl: './alexandria-desktop.component.html',
  styleUrls: ['./alexandria-desktop.component.scss'],
  animations: [rowsAnimation]
})
export class AlexandriaDesktopComponent implements OnInit {

  imagesForPage = [
    { name: "case_image_1.png" },
    { name: "case_image_2.png" },
    { name: "case_image_3.png" },
    { name: "case_image_4.png" },
    { name: "case_image_5.png" },
    { name: "case_image_6.png" },
    { name: "case_image_7.png" },
    { name: "case_logo.png" },
  ];

  images = [];

  constructor(
    private readonly _router: Router,
    private readonly _utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.images = this._utilsService.createRandomCaseStudies(1);
  }

  backClickEvent(): void {
    this._router.navigate(['../our-work']);
  }

  //#region Wheel functionality
  idlePeriod = 100;
  animationDuration = 1500;
  lastAnimation = 0;
  index: number = 0
  contentList: Array<boolean> = [
    true, false, false, false, false, false, false
  ]

  @HostListener("wheel", ["$event"])
  public async onWheel(event: WheelEvent) {
    const timeNow = new Date().getTime();

    if (timeNow - this.lastAnimation < this.idlePeriod + this.animationDuration) {
      event.preventDefault();
      return;
    }

    this.wheelLogic(event.deltaY);

    this.lastAnimation = timeNow;
  }

  wheelLogic(deltaY: number): Promise<boolean> {
    // Detect if scrolling up or down
    if (deltaY < 0)
      this.index--;
    else if (deltaY > 0)
      this.index++;

    // Reset index
    if (this.index > 6) this.index = 6;
    if (this.index < 0) this.index = 0;

    // Reset content list
    this.contentList = [false, false, false, false, false, false, false]
    this.contentList[this.index] = true;

    return new Promise(resolve => {
      setTimeout(() => resolve(false), 500);
    });
  }
  //#endregion
}
